import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { HiOutlineEmojiSad } from "@react-icons/all-files/hi/HiOutlineEmojiSad"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <main className="wrapper wrapper-error">
      <HiOutlineEmojiSad size='4rem'/>
      <h2>404: Not Found</h2>
      <p>
        Coś poszło nie tak, wyszukiwana podstrona jest nie istnieje, bądź
        połączenie z serwerem zostało przerwane.
      </p>
      <div className="error-options">
        <Link className='controllers-button' to='/'>Powrót</Link>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
